import {InjectionToken} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

import {ServerError} from '@models/response';

export type ControlStatus = 'valid' | 'invalid';

export class CustomFormError {
  constructor(
    public readonly key: string,
    public readonly value?: string | null,
    public readonly translate = true,
  ) { }
}

export type CustomFormErrorFn = (value?: {[key: string]: string | number} | string | number | null) => CustomFormError;

export interface CustomFormErrors {
  [key: string]: CustomFormErrorFn;
}

export const defaultErrors: CustomFormErrors = {
  required: () => new CustomFormError('errors.required'),
  validEmail: () => new CustomFormError('errors.email'),
  emailDuplicate: () => new CustomFormError('errors.emailDuplicate'),
  validEmails: () => new CustomFormError('errors.emails'),
  validPhone: () => new CustomFormError('errors.phone'),
  duplicatePhone: () => new CustomFormError('errors.duplicatePhone'),
  validPassword: () => new CustomFormError('errors.password'),
  match: () => new CustomFormError('errors.passwordMismatch'),
  validNumber: () => new CustomFormError('errors.number'),
  validIntegerNumber: () => new CustomFormError('errors.integerNumber'),
  validMinNumber: (value: number) => new CustomFormError('errors.minNumber', value.toString()),
  validMaxNumber: (value: number) => new CustomFormError('errors.maxNumber', value.toString()),
  validMinRange: () => new CustomFormError('errors.minRange'),
  validMaxRange: () => new CustomFormError('errors.maxRange'),
  validDate: () => new CustomFormError('errors.date'),
  matDatepickerMin: () => new CustomFormError('errors.matDatepickerMin'),
  matDatepickerMax: () => new CustomFormError('errors.matDatepickerMax'),
  matDatepickerRange: () => new CustomFormError('errors.matDatepickerRange'),
  server: (message) => new CustomFormError(`${message}`, null, false),
  valueLength: (value: string) => new CustomFormError('errors.valueLength', value),
  duplicate: (message) => new CustomFormError(`${message}`),
  maxlength: (err: {[key: string]: number}) => new CustomFormError('errors.maxLength', err && err.requiredLength.toString()),
  minlength: (err: {[key: string]: number}) => new CustomFormError('errors.minLength', err && err.requiredLength.toString()),
  minSelectValue: (value: number) => new CustomFormError('errors.minSelectValue', value.toString()),
  maxSelectValue: (value: number) => new CustomFormError('errors.maxSelectValue', value.toString()),
  unchoosenOption: () => new CustomFormError('errors.autocomplete'),
  comparisonDates: () => new CustomFormError('errors.comparisonDates'),
  comparisonDepartureArrivalDates: () => new CustomFormError('errors.comparisonArrivalDates'),
  comparisonFromTo: () => new CustomFormError('errors.comparisonFromTo'),
  comparisonFromToControls: () => new CustomFormError('errors.comparisonFromToControls'),
  comparisonStartEnd: () => new CustomFormError('errors.comparisonStartEnd'),
  validTime: () => new CustomFormError('errors.time'),
  validBreak: () => new CustomFormError('errors.breakError'),
  invalidCharterEndTime: () => new CustomFormError('errors.invalidCharterEndTime'),
  invalidPeriodEndTime: () => new CustomFormError('errors.invalidPeriodEndTime'),
  validUrl: () => new CustomFormError('errors.url'),
  stubError: () => new CustomFormError('', null, false),
  invalidEndHour: () => new CustomFormError('errors.invalidGeneratorHour'),
  validLatin: () => new CustomFormError('errors.latin'),
  validRunningHours: () => new CustomFormError('errors.runningHours'),
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  factory: () => defaultErrors,
});

export const serverFormErrors = (errors: ServerError, form: UntypedFormGroup): void => {
  if (!errors) {
    return;
  }
  Object.keys(errors).forEach((key) => {
    const control = form.controls[key];
    if (control) {
      control.setErrors({server: errors[key]});
      control.markAsTouched();
    }
  });
};
