<ng-container *transloco="let t">
  <p [class.hide]="hide" class="form__block-error">
    <ng-container *ngIf="displayedError">
      <ng-container *ngIf="displayedError?.translate; else displayText">
        {{ t(displayedError?.key, {value: displayedError?.value}) }}
      </ng-container>
      <ng-template #displayText>
        {{ displayedError?.key }}
      </ng-template>
    </ng-container>
  </p>
</ng-container>
